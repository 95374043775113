/**
 * Add this to a component listed for a route in
 * router/index.js and it will be accessible from
 * a public route as long as it has a st=? get query
 * representing a short token, to login with
 */
export default {
  created() {
    this.$store.dispatch('getScope').then(({ scope }) => {
      const { user } = scope

      return this.$store.dispatch('setScope', {
        scope: {
          user
        },
        skipBaseValues: true
      })
    })
  }
}
